import { OneTrustProvider } from '@grandvisionhq/cookie-consent'
import { createLocalRouteFormatter } from '@grandvisionhq/frk-routing'
import { createAppComponent } from '@grandvisionhq/www-next/pages/app'

import {
  featureToggles,
  getAddressConfig,
  getAdyenConfig,
  getApiConfig,
  getAuthConfig,
  getGoogleMapsConfig,
  INTL_DEFAULTS,
  intlConfigMap as intl,
} from '../configuration'
import { localRouteFormatters } from '../configuration/manifest'

import '@grandvisionhq/iris-ui/themes/at-pearle.theme.css'
import '@grandvisionhq/iris-ui/style.css'
import '../styles/index.scss'

const settings = {
  intl,
  featureToggles,
  routing: {
    formatters: [createLocalRouteFormatter({ formatters: localRouteFormatters })],
  },
  address: getAddressConfig(),
  auth: getAuthConfig(),
  adyen: getAdyenConfig(),
  apiConfig: getApiConfig(),
  googleMaps: getGoogleMapsConfig(),
}

const App = await createAppComponent({
  defaultLocale: INTL_DEFAULTS.locale,
  consentProvider: OneTrustProvider,
  settings,
})

export default App
